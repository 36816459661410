<template>
  <div :class="[{ 'form-inline': inline }, { 'form-group': group }]">
    <button
        class="btn"
        :type="type"
        :class="[btnClass, { 'btn-loading': loading }]"
        :disabled="disabled || loading"
        v-on:click="onclick"
    >
      {{ directlabel || $t(label) }}
    </button>
  </div>
</template>

<script>
require('../../utilities/button')
export default {
  props: {
    label: {},
    disabled: {},
    loading: {},
    directlabel: {},
    btnClass: {},
    type: {},
    inline: {
      default() {
        return false
      }
    },
    group: {
      default() {
        return true
      }
    }
  },
  methods: {
    onclick(evt) {
      this.$emit('btnclick', evt)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../utilities/config';
</style>
